import React, { useContext } from "react";
import token from "../../assets/logo/token.png";
import { UserContext } from "../../store/context/UserContext";

const FarmHeader = () => {
  const {
    userData: { tokenBalance },
  } = useContext(UserContext);

  return (
    <div className="farm_header glass-effect">
      <div className="farm_header-content flex-between">
        <div className="token-info flex">
          <img src={token} alt="token" className="token-logo" />
          <h3 className="token-title">
            <b>Available Balance</b>
          </h3>
        </div>
        <div className="balance-display">
          <h3 className="balance-amount">
            {new Intl.NumberFormat("en-US", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 4,
            }).format(tokenBalance)}{" "}
            USDT
          </h3>
        </div>
      </div>
    </div>
  );
};

export default FarmHeader;
