const TOKEN_ADDRESS = {
 56: "0x55d398326f99059fF775485246999027B3197955",
  //4002 : "0xD974d9B3b48f6b4F9f473Ce3c175670b0A60A16a",
 // 1: "https://mainnet.infura.io/v3/ec03b8dcd95348149519e0be7ac5098e", // Ethereum Mainnet
   // 97: "0x55d398326f99059fF775485246999027B3197955", // BNB Testnet
    137: "0x609E19E088D1564731BC6C7a4d7d1d7C0600D579", // Polygon Mainnet
};

const STAKING_ADDRESS = {
   56: "0x41Bd7053bf40e307559bA6E7C71943dF0A8aaC92",
 //4002 : "0x3f452c35b16c69dfd5437aa0a06d612021b2477e",
 // 1: "https://mainnet.infura.io/v3/ec03b8dcd95348149519e0be7ac5098e", // Ethereum Mainnet
    //97: "0x41Bd7053bf40e307559bA6E7C71943dF0A8aaC92", // BNB Testnet
    137: "0x1014382677232ac842109d0acad0e30314e0b427", // Polygon Mainnet
};

export { TOKEN_ADDRESS, STAKING_ADDRESS };
