import React, { useContext, useState, useCallback, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ReactComponent as IncomeIcon } from '../../assets/icons/active_icon.svg';
import { ReactComponent as PassiveIcon } from '../../assets/icons/passive_icon.svg';
import { ReactComponent as DirectIcon } from '../../assets/icons/direct_icon.svg';
import { ReactComponent as TeamIcon } from '../../assets/icons/team_icon.svg';
import { ReactComponent as ActiveIcon } from '../../assets/icons/income_icon.svg';
import { ReactComponent as StatusIcon } from '../../assets/icons/status_icon.svg';
import { ReactComponent as RankIcon } from '../../assets/icons/rank_icon.svg';
import { ReactComponent as StakeIcon } from '../../assets/icons/stake_icon.svg';
import ProfileImg from "../../assets/images/profile.jpeg";
import "./ProfilePage.scss";
import ChangeCover from "../../components/ProfileDetails/ChangeCover";
import ChangeProfileImage from "../../components/ProfileDetails/ChangeProfileImage";
import EditProfile from "../../components/ProfileDetails/EditProfile";
import { useUserStore } from "../../store/userStore";
import { formatAddress } from "../../utils/helpers";
import { useWeb3React } from "@web3-react/core";
import Farms from "../../pages/Home/Farms";
import ReferrerComponent from '../../components/ReferrerComponent';

import { useUserStakingData } from "../../hooks";
import BottomMenu from "../../components/BottomMenu";
import ReferralsModal from "../../components/Modals/ReferralModal";
import { withdrawReferralEarnings,
   checkUserLevel ,
    claimAll,
     checkStatus, 
      getEarningsAll,
       getTotalWithdrawals,  } from "../../utils/contractMethods";
import { IDepositData } from "../../store/types";
import DepositModal from "../../components/Modals/DepositModal";
// import FarmStats from "../Home/FarmStats";
import { TransactionContext } from "../../store/context/TransactionContext";
// import { AnimatePresence } from "framer-motion";
// import { Button, UnlockWallet } from "../../components";
// import FarmHeader from "../Home/FarmHeader";
// import { ReactComponent as Loader } from "../../assets/icons/transaction_processing.svg";
// import Deposit from "../Home/Deposit";

import {
  getUserDirectReferrerIncome24hrs,
  getUserPartnerReferrerIncome24hrs,
  getUserDirectReferrerCount,
  getUserPartnerReferrerCount,
  getUserTotalStaked,
  getUserStakingDetails,
  getUserTotalClaimed,
  // getRewards
} from "../../utils/userMethods";

const ProfilePage: React.FC = () => {
  const [depositModal, setDepositModal] = useState<IDepositData | null>(null);
  const [openBannerModal, setOpenBannerModal] = useState(false);
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  // const [earningsLast24Hours, setEarningsLast24Hours] = useState<number>(0);
  const [openReferralsModal, setOpenReferralsModal] = useState(false);
  const [userStatus, setUserStatus] = useState<string>('');
  const [userLevel, setUserLevel] = useState<string>('');

  const userData = useUserStore((store) => store.userData);
  const { account, library, chainId } = useWeb3React();
  const [partnerIncome, setPartnerIncome] = useState(0);
  const [directIncome, setDirectIncome] = useState(0);
  const [previousPartnerIncome, setPreviousPartnerIncome] = useState(0);
  const [previousDirectIncome, setPreviousDirectIncome] = useState(0);
  const [directCount, setDirectCount] = useState(0);
  const [partnerCount, setPartnerCount] = useState(0);
  const [totalStake, setTotalStake] = useState(0);
  // const [totalIncome, setTotalIncome] = useState(0);
  const [totalClaimed, setTotalClaimed] = useState(0);
  const [referralEarnings, setReferralEarnings] = useState(0);
  const [earningsAll, setEarningsAll] = useState<number>(0);
  const [totalWithdrawals, setTotalWithdrawals] = useState<number>(0);
  // const [totalRewardsWithInitial, setTotalRewardsWithInitial] = useState<number>(0);
  const [totalRewards, setTotalRewards] = useState<number>(0);
  // const [dailyReward, setDailyReward] = useState<number>(0);
  
  const handleGetContractDetails = async () => {};

  const { userData: user } = useUserStakingData();

  const handleGetUserData = useCallback(async () => {
    if (account && chainId) {
      const { provider } = library;
      try {
        const earningsAll = await getEarningsAll(account, provider, chainId, account);
        setEarningsAll(Number(earningsAll));

        const directIncome = await getUserDirectReferrerIncome24hrs(account, provider, chainId);
        const partnerIncome = await getUserPartnerReferrerIncome24hrs(account, provider, chainId);
        const directCount = await getUserDirectReferrerCount(account, provider, chainId);
        const partnerCount = await getUserPartnerReferrerCount(account, provider, chainId);
        const totalStakedAmount = await getUserTotalStaked(account, provider, chainId);
        const { referralEarnings } = await getUserStakingDetails(account, provider, chainId);
        const totalClaimedIncome = await getUserTotalClaimed(account, provider, chainId);
        const totalWithdrawals = await getTotalWithdrawals(account, provider, chainId, account);

        const directIncomeNumber = Number(directIncome);
        const partnerIncomeNumber = Number(partnerIncome);
        const referralEarningsNumber = Number(referralEarnings);
        const totalClaimedIncomeNumber = Number(totalClaimedIncome);
        const totalWithdrawalsNumber = Number(totalWithdrawals);

        const totalIncome = directIncomeNumber + partnerIncomeNumber + referralEarningsNumber + totalClaimedIncomeNumber  ;
        setDirectIncome(directIncomeNumber);
        setPartnerIncome(partnerIncomeNumber);
        setDirectCount(Number(directCount));
        setPartnerCount(Number(partnerCount));
        setTotalStake(Number(totalStakedAmount));
        // setTotalIncome(totalIncome);
        setTotalClaimed(totalClaimedIncomeNumber);
        setReferralEarnings(referralEarningsNumber);
        setTotalWithdrawals(totalWithdrawalsNumber);
        setPreviousDirectIncome(directIncomeNumber);
        setPreviousPartnerIncome(partnerIncomeNumber);
        setTotalRewards(totalRewards);

        const level = await checkUserLevel(account, provider, chainId, account);
        setUserLevel(level);
        
        const status = await checkStatus(account, provider, chainId, account);
        setUserStatus(status);
      } catch (error: any) {
        console.log(error);
      }
      
    }
  }, [account, library, chainId]);
  
  
  

  useEffect(() => {
    handleGetUserData();
  }, [handleGetUserData]);

  const handleOpenReferralsModal = () => {
    setOpenReferralsModal(true);
  };

  const handleCloseReferralsModal = () => {
    setOpenReferralsModal(false);
  };

 

  const onClaimEarnings = async () => {
    if (account && chainId) {
      const { provider } = library;
      try {
        await withdrawReferralEarnings(account, provider, chainId);
        await handleGetUserData();
      } catch (error: any) {
        console.log("Failed to claim earnings: " + error.message);
      }
    }
  };

  const handleClaimAll = async () => {
    if (account && chainId) {
      const { provider } = library;
      try {
        await claimAll(account, provider, chainId);
        console.log();
        await handleGetUserData();
      } catch (error: any) {
        console.log("Failed to claim all: " + error.message);
      }
    }
  };

  // const handleApprove = async () => {
  //   if (!account || !chainId) return;
  //   try {
  //     setTransaction({ loading: true, status: "pending" });
  //     const { setApprove } = await import("../../utils/userMethods");
  //     await setApprove(account, library?.provider, chainId);
  //     await refetch();
  //     setTransaction({ loading: true, status: "success" });
  //   } catch (error: any) {
  //     if (error?.code === 4001) {
  //       return setTransaction({
  //         loading: true,
  //         status: "error",
  //         message: error.message,
  //       });
  //     }
  //     setTransaction({ loading: true, status: "error" });
  //   }
  // };

  
  // const renderMethods = (
  //   <div className="form_container">
  //     {<Deposit setDepositModal={setDepositModal} />}
     
      
  //   </div>
  // );




  toast.configure();
  
  const handleCardClick = (message: string) => {
    toast.info(message, { position: toast.POSITION.TOP_RIGHT });
  };
  
 
  return (
    <>

<ToastContainer />
<div className="card-container2">
      <div className="profile-page" style={{ backgroundImage: userData?.bannerImg ? `url(${userData.bannerImg})` : undefined }}>
        <div className="mx">
          <div className="container">
            <div className="camera-content" onClick={() => setOpenBannerModal(true)}>
              <div className="camera-icon">{/* SVG for camera icon */}</div>
              <div className="text">
                <h6>Change Cover</h6>
              </div>
            </div>
            <div className="profile-content">
              <div className="profile-img">
                <img src={userData?.profileImg ?? ProfileImg} alt="" />
                <div className="upload-content" onClick={() => setOpenProfileModal(true)}>
                  {/* SVG for camera icon */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card3">
        <div className="edit-profile-content">
          <div className="edit-profile" onClick={() => setEditProfile(true)}>
            {/* SVG for pen icon */}
            <h5>Edit</h5>
          </div>
        </div>
        <h5>{userData?.username ?? formatAddress(account ?? '')}</h5>
        <div className="referrer-details">
          {user.referrer && (
            <>
              <h5>Sponsor {formatAddress(user.referrer)}</h5>
              <button className="open-referrals-btn" onClick={handleOpenReferralsModal}>
                View Referrals
              </button>
            </>
          )}
        </div>
      </div>

      <div className="card2-container">
        <div className="card image3" onClick={() => handleCardClick('Viewing Total Income')}>
          <IncomeIcon className="card-icon" />
          <h5>TOTAL INCOME</h5>
          <p>{totalWithdrawals + earningsAll + referralEarnings} USDT</p>
          
        </div>
        <div className="card" onClick={() => handleCardClick('Viewing Total Passive Income')}>
          <PassiveIcon className="card-icon" />
          <h5>TOTAL PASSIVE</h5>
          <p>{totalClaimed} USDT</p>
          <div className="highlight">
            <p className={ earningsAll > 0 ? 'income-increase' : ''}>
              {earningsAll} USDT { earningsAll > 0 && <span className="arrow-up">↑</span>}
            </p>
          </div>
        </div>
        <div className="card2 image2" onClick={() => handleCardClick('Viewing Direct Income')}>
          <DirectIcon className="card-icon" />
          <h5>DIRECT</h5>
          <p>{directCount}</p>
          <div className="highlight">
            <p className={directIncome > 0 ? 'income-increase' : ''}>
              {directIncome} USDT {directIncome > 0 && <span className="arrow-up">↑</span>}
            </p>
          </div>
        </div>
        <div className="card2 image1" onClick={() => handleCardClick('Viewing Total Team Income')}>
          <TeamIcon className="card-icon" />
          <h5>TEAM</h5>
          <p>{partnerCount }</p>
          <div className="highlight">
            <p className={partnerIncome  > 0 ? 'income-increase' : ''}>
              {partnerIncome } USDT {partnerIncome  > 0 && <span className="arrow-up">↑</span>}
            </p>
          </div>
        </div>
        <div className="card" onClick={() => handleCardClick('Viewing Active Income')}>
          <ActiveIcon className="card-icon" />
          <h5>ACTIVE</h5>
          <p>{referralEarnings} USDT</p>
          <button onClick={onClaimEarnings}>Withdraw</button>
        </div>
        <div className="card" onClick={() => handleCardClick('Viewing Passive Income')}>
          <PassiveIcon className="card-icon" />
          <h5>PASSIVE</h5>
          <p>{earningsAll} USDT</p>
          <button className="claim-all-btn" onClick={handleClaimAll}>Claim All</button>
        </div>
        <div className="card" onClick={() => handleCardClick('Viewing ID Status')}>
          <StatusIcon className="card-icon" />
          <h5>ID STATUS</h5>
          <p>{userStatus}</p>
        </div>
        <div className="card" onClick={() => handleCardClick('Viewing My Rank')}>
          <RankIcon className="card-icon" />
          <h5>RANK</h5>
          <p>{userLevel}</p>
        </div>
        <div className="card" onClick={() => handleCardClick('Viewing My Staked')}>
          <StakeIcon className="card-icon" />
          <h5>STAKED</h5>
          <p>{totalStake} USDT</p>
        </div>
      </div>

    </div>
      





 



{openBannerModal && (
  <ChangeCover
    onClose={() => setOpenBannerModal(false)}
    onUpdate={handleGetUserData}
  />
)}

{openProfileModal && (
  <ChangeProfileImage
    onClose={() => setOpenProfileModal(false)}
    onUpdate={handleGetUserData}
  />
)}

{editProfile && (
  <EditProfile
    onClose={() => setEditProfile(false)}
    // onUpdate={handleGetUserData}
  />
)}

{depositModal && (
  <DepositModal
    key={"deposit"}
    modal={depositModal ? true : false}
    handleClose={() => setDepositModal(null)}
    data={depositModal}
    handleGetContractDetails={handleGetContractDetails}
  />
)}

        <ReferralsModal
          isOpen={openReferralsModal}
          onClose={handleCloseReferralsModal}
          referralEarnings={referralEarnings}
          referredUsers={directCount + partnerCount}
          referralLevel={4}
          onClaimEarnings={onClaimEarnings}
        />
      
    </>
  );
};


export default ProfilePage;
