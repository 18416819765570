import React, { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import { fetchRecentActivity } from '../../utils/contractMethods';
import styles from './Farms.module.scss';

const contractAddress = '0x41Bd7053bf40e307559bA6E7C71943dF0A8aaC92';
const provider = new ethers.providers.JsonRpcProvider('https://bsc-dataseed.binance.org/');

const shortenAddress = (address: string) => `${address.slice(0, 6)}...${address.slice(-4)}`;
const shortenAmount = (amount: string) => {
  const value = parseFloat(amount);
  if (value >= 1e6) return (value / 1e6).toFixed(2) + 'M';
  if (value >= 1e3) return (value / 1e3).toFixed(2) + 'K';
  return value.toFixed(2); // Updated to display two decimal places
};

const Farms: React.FC = () => {
  const [activities, setActivities] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [visibleActivities, setVisibleActivities] = useState<number>(5); // Initial number of visible activities
  const [isExpanded, setIsExpanded] = useState<boolean>(false); // State for expand/collapse

  const fetchActivity = async () => {
    try {
      setLoading(true);
      const recentActivity = await fetchRecentActivity(contractAddress, provider);
      setActivities(recentActivity);
    } catch (error) {
      console.error('Error fetching recent activity:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchActivity();
  }, []);

  const handleViewMore = () => {
    setIsExpanded(true); // Expand view
    setVisibleActivities(activities.length); // Show all activities
  };

  const handleViewLess = () => {
    setIsExpanded(false); // Collapse view
    setVisibleActivities(5); // Show initial number of activities
  };

  return (
    <div>
      <h2 className={styles.title}>Recent Activity</h2>

      <div className={styles.farmsContainer}>
        {loading ? (
          <p className={styles.loadingText}>Loading...</p>
        ) : (
          <div className={styles.activityList}>
            {activities.slice(0, visibleActivities).map((activity, index) => (
              <div key={index} className={styles.activityItem}>
                <div className={styles.iconWrapper}>
                  {activity.name === 'Staked' && <span className={styles.iconStaked}>💰</span>}
                  {activity.name === 'Reward' && <span className={styles.iconReward}>🏆</span>}
                  {activity.name === 'Claimed' && <span className={styles.iconClaimed}>🛡️</span>}
                  {activity.name === 'Withdrawn' && <span className={styles.iconWithdrawn}>💸</span>}
                  {activity.name === 'ReferralEarningsWithdrawn' && <span className={styles.iconWithdrawn}>🛡️</span>}
                </div>
                <div className={styles.activityDetails}>
                  <div className={styles.activityHeader}>
                    <span className={styles.eventName}>{activity.name}</span>
                    <span className={styles.userAddress}>{shortenAddress(activity.user)}</span>
                  </div>
                  <div className={styles.activityFooter}>
                    <span className={styles.amount}>{shortenAmount(activity.amount)}</span>
                    <button 
                      className={styles.transactionButton}
                      onClick={() => window.open(`https://testnet.ftmscan.com/tx/${activity.transactionHash}`, '_blank')}
                    >
                      Details
                    </button>
                  </div>
                </div>
              </div>
            ))}


{!loading && activities.length > 4 && (
        <div className={styles.exploreButtonContainer}>
          {isExpanded ? (
            <button className={styles.viewMoreButton} onClick={handleViewLess}>
              View Less
            </button>
          ) : (
            <button className={styles.viewMoreButton} onClick={handleViewMore}>
              View More Transactions
            </button>
          )}
        </div>
      )}
          </div>
        )}

        
      </div>

    
    </div>
  );
};

export default Farms;
