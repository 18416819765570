import React, { useContext, useState } from "react";
import { Button } from "../../components";
import { TransactionContext } from "../../store/context/TransactionContext";
import { IDepositData } from "../../store/types";
import TradingViewWidget from "../../components/TradingViewWidget"; // Import TradingView chart component
import styles from "./Deposit.module.scss";
import FarmHeader from "./FarmHeader";
import Farms from "./Farms";

interface IDeposit {
  setDepositModal: React.Dispatch<React.SetStateAction<IDepositData | null>>;
}

const Deposit: React.FC<IDeposit> = ({ setDepositModal }) => {
  const { loading } = useContext(TransactionContext);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [selectedAmount, setSelectedAmount] = useState<number | null>(null);
  const [tradeHistory, setTradeHistory] = useState<{ amount: number, price: number, time: string }[]>([
    { amount: 0.5, price: 50000, time: "2024-09-25 14:20:30" },
    { amount: 1.0, price: 51000, time: "2024-09-25 14:15:12" },
    { amount: 0.2, price: 49500, time: "2024-09-25 13:40:45" },
  ]); // Fake trade history

  const handleAddClick = (amount: number) => {
    setSelectedAmount(amount);
    setShowConfirmation(true);
  };

  const handleConfirm = () => {
    if (selectedAmount !== null) {
      setDepositModal({
        modal: true,
        depositAmount: selectedAmount.toString(),
      });

      // Simulate adding a new trade to the history
      const newTrade = {
        amount: selectedAmount,
        price: 50000 + Math.random() * 2000, // Random price
        time: new Date().toLocaleString(),
      };
      setTradeHistory([newTrade, ...tradeHistory]);

      setShowConfirmation(false);
    }
  };

  return (
    <div className={styles.container}>
   < FarmHeader/>

      {/* TradingView BTC Chart */}
      {/* <div className={styles.tradingViewBox}>
        <h3 className={styles.chartTitle}>BTC/USDT</h3>
        <TradingViewWidget symbol="BTCUSDT" />
      </div> */}

      {/* Input for amount */}
      <div className={styles.tradeBox}>
        <h3 className={styles.title}>Trade Bitcoin</h3>
        <input
          type="number"
          placeholder="Enter amount in USDT"
          onChange={(e) => setSelectedAmount(Number(e.target.value))}
          className={styles.amountInput}
        />
        <Button
          onClick={() => handleAddClick(selectedAmount || 0)}
          disabled={loading}
          className={styles.buyButton}
        >
          Buy BTC
        </Button>
      </div>

      {/* Fake trade confirmation modal */}
      {showConfirmation && (
        <div className={styles.confirmationModal}>
          <h4>Confirm Your Trade</h4>
          <p>Amount: {selectedAmount} USDT</p>
          <div className={styles.modalButtons}>
            <Button onClick={handleConfirm}>Confirm</Button>
            <Button
              onClick={() => setShowConfirmation(false)}
              className={styles.cancelButton}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}

      {/* Trade History */}
      <div className={styles.historyBox}>
        <h3 className={styles.historyTitle}>Trade History</h3>
        <div className={styles.historyList}>
          {tradeHistory.map((trade, index) => (
            <div key={index} className={styles.historyItem}>
              <span>{trade.time}</span>
              <span>{trade.amount} BTC</span>
              <span>@ {trade.price.toFixed(2)} USDT</span>

            </div>
          ))}
        </div>

      </div>

    </div>
  );
};

export default Deposit;
