import React from "react";
import "./Home.scss";
import Farm from "./Farm";
import ProfilePage from "../ProfilePage/index";
import Farms from "./Farms";


const Home: React.FC = () => {
  return (
  
    <div className="pad">
      <div className="home">
        <ProfilePage />
        <Farm />
        <Farms/>

      </div>
      
    </div>

  );

  
};

export default Home;
