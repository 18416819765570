import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { ethers } from "ethers";

// Default chain ID
let CHAINID = 56; // Fantom Testnet

// Injected Connector for MetaMask
export const Injected = new InjectedConnector({
  supportedChainIds: [4002, 97, 137, 56], // Fantom Testnet, BNB Testnet, Polygon
});

// Function to return RPC URL based on chain ID
const getRpcUrl = (chainId: number) => {
  switch (chainId) {
    case 4002: // Fantom Testnet
      return "https://rpc.testnet.fantom.network";
    case 56: // BNB Testnet
      return "https://bsc-dataseed.binance.org/";
    case 137: // Polygon Mainnet
      return "https://rpc-mainnet.maticvigil.com";
    default:
      return "https://mainnet.infura.io/v3/ec03b8dcd95348149519e0be7ac5098e"; // Ethereum Mainnet fallback
  }
};

// Function to create a new WalletConnect instance
export const createWalletConnectConnector = (chainId = CHAINID) => {
  return new WalletConnectConnector({
    rpc: {
      [chainId]: getRpcUrl(chainId),
    },
    bridge: "https://bridge.walletconnect.org",
    qrcode: true,
    pollingInterval: 12000,
  });
};

// Function to switch network
const switchRequest = (chainId = CHAINID) => {
  const { ethereum } = window as any;
  return ethereum.request({
    method: "wallet_switchEthereumChain",
    params: [{ chainId: ethers.utils.hexlify(chainId) }],
  });
};

// Function to handle network switching
export const switchNetwork = async (chainId = CHAINID) => {
  const { ethereum } = window as any;

  // Update CHAINID globally
  CHAINID = chainId;

  // Switch network on MetaMask
  if (ethereum) {
    try {
      await switchRequest(chainId);
    } catch (error) {
      console.error(error);
    }
  }

  // Create a new WalletConnect instance with the updated chain ID
  return createWalletConnectConnector(chainId);
};
