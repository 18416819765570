import React from 'react';
import { motion } from 'framer-motion';
import './LevelDetailsPopup.scss';
import Backdrop from './Backdrop';
import { modalVaraints } from '../../helpers/animation';
// In LevelDetailsModal.tsx
import { LevelStatus } from '../Modals/ReferralModal'; // Adjust path as needed

interface ILevelDetailsPopupProps {
  isOpen: boolean;
  onClose: () => void;
  level: LevelStatus;
}

const LevelDetailsPopup: React.FC<ILevelDetailsPopupProps> = ({ isOpen, onClose, level }) => {
  const getLevelRequirements = (level: LevelStatus) => {
    switch (level) {
      case LevelStatus.NEWBIE:
        return {
          directReferrals: 0,
          selfDeposit: '0',
          Reward: '0',

        };
      case LevelStatus.SILVER:
        return {
          DirectReferrals: 25,
          SelfDeposit: '$500',
          Reward: '$50',
        };
      case LevelStatus.GOLDEN:
        return {
          directReferrals: 50,
          selfDeposit: '$1000',
          Reward: '$100',

        };
        case LevelStatus.BRONZI:
        return {
          directReferrals: 100,
          selfDeposit: '$2000',
          Reward: '$200',

        };
      case LevelStatus.DIAMOND:
        return {
          directReferrals: 150,
          selfDeposit: '$4000',
          Reward: '$400',


        };
      default:
        return {
          directReferrals: 0,
          teamReferrals: 0,
          selfDeposit: '$0',
          totalDeposit: '$0',
          Reward: '0',

        };
    }
  };

  const { directReferrals, teamReferrals, selfDeposit, totalDeposit, Reward } = getLevelRequirements(level);

  return (
    <Backdrop handleClose={onClose} isOpen={isOpen}>
      <motion.div
        className="level-details-popup"
        onClick={(e) => e.stopPropagation()}
        variants={modalVaraints}
        animate="animate"
        initial="initial"
        exit="exit"
      >
        <div className="level-details-popup-content">
          <h3>Level {LevelStatus[level]} - Achieve Details</h3>
          <p>Direct Referrals Needed: {directReferrals}</p>
          <p>Self Deposit: {selfDeposit}</p>
          <h3>Royalty Reward: {Reward}</h3>

          <button onClick={onClose}>Close</button>
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default LevelDetailsPopup;
