import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Header, BottomMenu } from './components'; // Ensure BottomMenu is imported here
import { useEagerConnect } from './hooks/useEagerConnect';
import { Home } from './pages';
import ProfilePage from './pages/ProfilePage';
import LandingPage from './pages/LandingPage';
import TransactionHistory from './components/TransactionHistory'; // Adjust the path as necessary
import PartnerReferrerDetails from '../src/components/getPartnerReferrerDetails'; // Adjust the path as necessary
import ChatWidget from './components/ChatWidget';

import 'aos/dist/aos.css';
import AOS from 'aos';

const App: React.FC = () => {
  useEffect(() => {
    AOS.init({
      easing: 'ease-out-cubic',
    });
  }, []);

  useEagerConnect();
  const location = useLocation();
  
  // Determine if BottomMenu should be shown
  const showBottomMenu = location.pathname === '/';

  return (
    <div>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/landingpage" element={<LandingPage />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/transactions" element={<TransactionHistory />} />
        <Route path="/referrer" element={<PartnerReferrerDetails />} /> {/* Add the route for PartnerReferrerDetails */}
      </Routes>
      {/* ToastContainer to display notifications */}
      <div>
        {/* Other components */}
        <ChatWidget />
      </div>
    </div>
  );
};

export default App;
