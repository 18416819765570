import React, { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import styles from './TransactionHistory.module.scss'; // Assuming styles are in this file
import { getPartnerReferrerDetails } from '../utils/contractMethods';

const PartnerReferrerDetails: React.FC = () => {
  const [partners, setPartners] = useState<string[]>([]);
  const [stakeAmounts, setStakeAmounts] = useState<string[]>([]); // Change to string to handle formatted amounts
  const [loading, setLoading] = useState(true);
  const [provider, setProvider] = useState<ethers.providers.Web3Provider | null>(null);
  const [userAddress, setUserAddress] = useState<string | null>(null);

  useEffect(() => {
    const connectWallet = async () => {
      try {
        const { ethereum } = window as any; // Type assertion for Ethereum provider
        if (ethereum) {
          const web3Provider = new ethers.providers.Web3Provider(ethereum as ethers.providers.ExternalProvider);
          setProvider(web3Provider);

          // Request user's account
          const accounts = await web3Provider.send("eth_requestAccounts", []);
          const address = accounts[0];
          setUserAddress(address);

          // Fetch user activity if address is available
          if (address) {
            await fetchData(address, web3Provider);
          }
        } else {
          console.error('No Ethereum provider found. Install MetaMask.');
        }
      } catch (error) {
        console.error('Error connecting wallet:', error);
      }
    };

    connectWallet();
  }, []);

  const fetchData = async (address: string, web3Provider: ethers.providers.Web3Provider) => {
    setLoading(true); // Start loading
    try {
      const partnerReferrerDetails = await getPartnerReferrerDetails(
        '0x41Bd7053bf40e307559bA6E7C71943dF0A8aaC92', // Replace with your contract address
        web3Provider,
        address
      );

      // Assuming the result contains partner addresses and stake amounts
      setPartners(partnerReferrerDetails.partners);
      
      // Format stake amounts using ethers.utils.formatUnits
      const formattedStakeAmounts = partnerReferrerDetails.stakeAmounts.map((amount: string) => 
        parseFloat(ethers.utils.formatUnits(amount, 18)).toFixed(2) // Format to 2 decimal places
      );
      setStakeAmounts(formattedStakeAmounts);
      
    } catch (error) {
      console.error('Error fetching partner referrer details:', error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div className={styles.partnerReferrerDetails}>
      <h2 className={styles.title}>Partner Referrer Details</h2>
      {loading ? ( // Show loading indicator
        <p>Loading...</p>
      ) : (
        <div className={styles.tableWrapper}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Partner Address</th>
                <th>Stake Amount (USDT)</th>
              </tr>
            </thead>
            <tbody>
              {partners.length > 0 ? (
                partners.map((partner, index) => (
                  <tr key={index} className={styles.tableRow}>
                    <td>{partner}</td>
                    <td>{stakeAmounts[index]} USDT</td> {/* Display formatted stake amount */}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={2}>No partner referrer details available.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default PartnerReferrerDetails;
