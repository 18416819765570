import React, { useState } from "react";
import styles from "./Roadmap.module.scss";

interface RoadmapItem {
  date: string;
  title: string;
  description: string;
  image: string;
}

const roadmapItems: RoadmapItem[] = [
  {

    date: "Q3 2024",
    title: "Royalty NFT:",
    description: "Explore the world of digital collectibles and earn royalties.:",
    image: "https://i.postimg.cc/Nj4yFCCM/image.jpg",
  },
    
  {
    date: "Q2 2024",
    title: "Trading Program:",
    description: "Engage in seamless and efficient trading with our advanced platform.",
    image: "https://i.postimg.cc/YSCvWNTx/mimage.jpg",
  },
  {date: "Q1 2024",
    title: "TRT Mining",
    description: "Discover the power of TRT mining and earn rewards.",
    image: "https://i.postimg.cc/gJwrMzzX/myimage.jpg",
  },
    
  {
    date: "Q4 2024",
    title: "10 Spot Program",
    description: "Participate in our exclusive 10 Spot Program for exciting opportunities",
    image: "https://i.postimg.cc/nLSCYjJf/yimage.jpg",
  },
  {
    date: "Q1 2025",
    title: "Expansion",
    description: "Expansion of features and global outreach.",
    image: "https://i.postimg.cc/xTXgKMVn/start-5-dai-coin.jpg",
  },
];

const Roadmap = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? roadmapItems.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === roadmapItems.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <section className={styles.roadmap}>
      <div className={styles.container}>
        <h2 className={styles.roadmapTitle}>Our Roadmap</h2>
        <div className={styles.roadmapWrapper}>
          <div
            className={styles.roadmapTimeline}
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
            {roadmapItems.map((item, index) => (
              <div key={index} className={styles.roadmapItem}>
                <div className={styles.roadmapContent}>
                  <img src={item.image} alt={item.title} />
                  <h4>
                    {item.date} - {item.title}
                  </h4>
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
          </div>
          <div className={styles.navButtons}>
            <button onClick={handlePrev}>{"<"}</button>
            <button onClick={handleNext}>{">"}</button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Roadmap;
