import React from "react";
import "./Footer.scss"; // Import the SCSS file

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <p>© 2024 Your Company. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
