import React, { useEffect, useRef, useState } from "react";
import autoAnimate from "@formkit/auto-animate";

import styles from "./Faq.module.scss";
import DownArrow from "../../assets/icons/down-arrow.svg";

const faqs = [
  {
    question: "Why is TRUST TRADE the best?",
    answer:
      "The cryptocurrency rate has no effect on the sustainability of the rewards.The minimum entry threshold is equal to just a couple of cups of coffee.There is no need to wait for payouts - funds come instantly to your wallet.Rewards always depend only on the actions of the participant.The participant can receive the reward indefinitely, as long as he expands his team",
  },
  {
    question: "Is TRUST TRADE safe?",
    answer:
      "The functioning of TRUST TRADE is fully blockchain-protected. Participant leaves no personal data and has only his cryptocurrency wallet address connected via WEB 3.0 technology as a login. The entire TRUST TRADE infrastructure is built on the operation of tamper-proof smart contracts, and the website is simply a projection of data from the blockchain. It makes no sense to hack into the website.",
  },
  {
    question: "How much does it cost to participate in TRUST TRADE USDT?",
    answer:
      "Participation in TRUST TRADE USDT is the automatic activation of  program. Activation costs start at 20 USDT, not including minimum fees from the Smart Chain (BNB Chain) blockchain in the BNB cryptocurrency.",
  },
  {
    question: "What do I need to get started in TRUST TRADE USDT?",
    answer:
      "No special knowledge is required. Every participant must have their own smartphone or laptop with an installed app (Trust Wallet, TokenPocket or MetaMask), which has a personal Smart Chain (BNB Chain) cryptocurrency wallet set up. To register, you need to have at least a minimum amount - 20 USDT, and some BNB for a blockchain fee.",
  },
  {
    question: "Benefits of participation in TRUST TRADE USDT program?",
    answer:
      "In TRUST TRADE USDT program you have access to 3 major benefits different in terms of profitability and condition of interaction between partners. You are entitled to all the three programs in parallel.Staking Income,Referral Income,Roality Income",
  },
  {
    question: "What is royalty income?",
    answer:
      "Royalty income means you will be rewarded based on the performance of the work you do.This royalty income has been kept for a purpose that appriciate and encouragement you.There are 3 types of royalty income in TRUST TRADE .1 st Bronze income,There are 3 types of royalty income in TRUST TRADE ,2 nd silver income ,3 rd gold income",
  },
];

const FaqCard = ({ question, answer }) => {
  const [show, setShow] = useState(false);
  const parent = useRef(null);

  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  const reveal = () => setShow(!show);

  return (
    <div ref={parent} className={styles["faq-card"]}>
      <div
        className={styles["faq-card-title"]}
        onClick={reveal}
      >
        <h5 className={styles["question"]}>
          {question}
        </h5>
        <img
          src={DownArrow}
          alt=""
          style={{
            transform: show ? "rotate(180deg)" : "rotate(0)",
            transition: "transform 0.2s linear",
          }}
        />
      </div>
      {show && (
        <p className={styles["faq-answer"]}>
          {answer}
        </p>
      )}
    </div>
  );
};

const Faq = () => {
  return (
    <section className={styles.faq}>
      <div className={styles["faq-wrapper"]}>
        <section className={styles.heading}>
          <h2>Frequently Asked Questions</h2>
        </section>
        <div className={styles["faq-section-wrapper"]}>
          <div className={styles["faq-card-wrapper"]}>
            {faqs.map((q, index) => (
              <FaqCard key={index.toString()} {...q} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
